<template>
  <div class="wrapper">
    <div class="text">
      <span>
        Text segment again explaining what was mentioned in the email. Lorem
        ipsum dolor sit amet, consectetur adipiscing elit. Praesent pretium
        facilisis velit. Pellentesque porta odio ante, vel interdum eros semper
        in. Mauris pretium sapien quis metus facilisis, fermentum hendrerit
        tortor dapibus. In hac habitasse platea dictumst. Integer a congue
        lorem. Morbi non fringilla mi, id pellentesque justo.
      </span>
    </div>
    <form class="form" @submit.prevent="handleSubmit">
      <div class="form-head">
        <div>
          <span>{{ user.fname }}</span>
        </div>
        <div>
          <span>{{ user.lname }}</span>
        </div>
      </div>
      <div class="form-body">
        <div class="form-body-email">
          <div>
            <span>Email:</span>
          </div>
          <div>
            <span>{{ user.email }}</span>
          </div>
        </div>
        <div class="form-body-password">
          <input
            type="password"
            name="password"
            v-model="user.password"
            placeholder="Create a password here"
          />
        </div>
      </div>
      <div class="form-footer">
        <div class="form-footer-checkbox">
          <input type="checkbox" name="check" v-model="checked" />
          <div>
            <small>
              Tick box to accept the T&C, etc.etc. By clicking Accept, you
              accept that an account is created.
            </small>
          </div>
        </div>
        <div class="form-footer-acceptBtn">
          <input
            type="submit"
            name="acceptBtn"
            value="Accept"
            :disabled="!checked"
            :class="{ active: checked }"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { authActionTypes, authMutationsTypes } from '@/store/modules/auth'
const {
  checkConfirmExecutor,
  changePass,
  loginExecutor,
  getCurrentUser,
} = authActionTypes
const { isLoggedIn } = authMutationsTypes
export default {
  neme: 'BhConfirmExecutor',
  created() {
    const searchParams = new URLSearchParams(window.location.search)
    const token = searchParams.get('token')
    const userId = searchParams.get('userId')

    this.$store
      .dispatch(checkConfirmExecutor, { token, userId })
      .then(res => {
        if (res.success) {
          this.user.lname = res.lname
          this.user.fname = res.fname
          this.user.email = res.executor_email
          this.$store
            .dispatch(loginExecutor, { email: res.executor_email })
            .then(() => {
              this.$store.dispatch(getCurrentUser)
            })
        }
      })
      .catch(Error)
  },
  data() {
    return {
      user: {
        lname: '',
        fname: '',
        email: '',
        password: '',
      },
      checked: false,
    }
  },
  methods: {
    handleSubmit() {
      const data = {
        oldPassword: '11111111',
        newPassword: this.user.password,
        c_newPassword: this.user.password,
      }
      this.$store
        .dispatch(changePass, data)
        .then(() => {
          this.$store.commit(isLoggedIn)
        })
        .catch(Error)
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;

  .text {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #ffffff;
    box-shadow: 0px 10px 60px rgba(88, 59, 117, 0.1);
    border-radius: 2px;

    margin: 6% 0 4% 0;

    span {
      padding: 3% 7%;
      line-height: 155%;
      font-size: 15px;
    }
  }

  .form {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    span {
      font-size: 15px;
    }
    &-head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3%;

      div {
        width: 50%;
        display: flex;
        justify-content: flex-start;
      }
    }

    &-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      div {
        margin: 1.5% 0;
      }

      &-email {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div:first-child {
          width: 11%;
          display: flex;
          justify-content: flex-start;
        }
        div:last-child {
          width: 89%;
          display: flex;
          justify-content: flex-start;
        }
      }

      &-password {
        width: 100%;

        input {
          width: 100%;
          background: #ffffff;
          border: 0.01em solid #464646;
          box-sizing: border-box;
          border-radius: 2px;
          padding: 2% 2%;

          &::placeholder {
            color: #a7a7a7;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }

    &-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      margin-top: 3%;

      &-checkbox {
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;

        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin-top: 1.8%;
          margin-right: 2%;

          &:hover {
            cursor: pointer;
          }

          width: 23px;
          height: 13px;
          background: #ffffff;
          border: 0.01em solid #464646;
          box-sizing: border-box;
          border-radius: 3px;

          &:checked {
            background-image: url('../../assets/ExecutorStyleIcons/checkboxStyle/checkboxChecked.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-size: 110%;
          }
        }

        span {
          font-size: 11px;
          width: 80%;
        }
      }

      &-acceptBtn {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 7px 14px rgba(88, 59, 117, 0.2);
        border-radius: 2px;
        color: #583b75;
        border: 1px solid #583b75;

        &:hover {
          cursor: pointer;
        }

        input {
          padding: 5% 0;
          width: 100%;
          &.active {
            background: #583b75;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
